import './styles/styles.scss';
import './js/main.js';

import { Tooltip, Toast, Popover } from 'bootstrap';


let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    tooltipTriggerEl.addEventListener('show.bs.tooltip', function () {
        tooltipTriggerList.map(function(trig) {
            if(trig !== tooltipTriggerEl) {
                let tooltip = Tooltip.getInstance(trig);
                tooltip.hide();
            }
        })
    });

    return new Tooltip(tooltipTriggerEl);
});

document.addEventListener("click", function(evnt){
    tooltipTriggerList.map(function(trig) {
        let tooltip = Tooltip.getInstance(trig);
        tooltip.hide();
    });
});

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl);
});

document.addEventListener("DOMContentLoaded", function(){
    let el = document.getElementById('navbar-top');
    let elMain = document.getElementById('main-content');

    if(el) {
        window.addEventListener('scroll', function () {
            let navbarHeight = el.offsetHeight;

            if (window.scrollY > navbarHeight) {
                el.classList.add('fixed-top');
                elMain.style.marginTop = navbarHeight*2 + 'px';
            } else {
                el.classList.remove('fixed-top');
                elMain.style.marginTop = '0';
            }
        });
    }
});
